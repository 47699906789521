import { ComputedRef } from 'vue'

/**
 * Composable for managing dynamic favicon and page title based on branding and feature flags
 */
export const useDynamicHead = () => {
  const branding = useBrand()
  const { $flagsmith } = useNuxtApp()

  const isV2Enabled = computed(() => {
    return !!$flagsmith?.hasFeature('case_wise_2')
  })

  const faviconUrl = computed(() => {
    if (branding.value.brandingExperience === 'white_label') {
      return branding.value.faviconUrl || '/favicon.svg'
    }

    if (!isV2Enabled.value) {
      return '/favicon.ico'
    }

    return '/favicon.svg'
  })

  /**
   * Apply dynamic head with correct favicon and title
   * @param customTitle Optional custom title or computed title
   */
  const applyHead = (customTitle?: string | ComputedRef<string>) => {
    let title: string | ComputedRef<string> = isV2Enabled.value ? 'CaseWise' : 'Claim Portal'

    if (customTitle) {
      title = customTitle
    }

    useHead(() => ({
      title: typeof title === 'object' ? title.value : title,
      link: [
        {
          rel: 'icon',
          type: faviconUrl.value.endsWith('.ico') ? 'image/x-icon' : 'image/svg+xml',
          href: faviconUrl.value,
        },
      ],
    }))
  }

  return {
    faviconUrl,
    isV2Enabled,
    applyHead,
  }
}
